import ApplicantService from 'src/modules/applicant/applicantService';
import selectors from 'src/modules/applicant/list/applicantListSelectors';
import { i18n } from 'src/i18n';
import exporterFields from 'src/modules/applicant/list/applicantListExporterFields';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';
import authSelectors from '../../../modules/auth/authSelectors';

import moment from 'moment';

const prefix = 'APPLICANT_LIST';
export const APPLICANT_OWNER = 'applicantOwner';
const actions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  NUMBER_FETCH_STARTED: `${prefix}_NUMBER_FETCH_STARTED`,
  NUMBER_FETCH_SUCCESS: `${prefix}_NUMBER_FETCH_SUCCESS`,

  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
  DEALSTAGE_CHANGED: `${prefix}_DEALSTAGE_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  RESET_FILTER: `${prefix}_RESET_FILTER`,

  doChangeSelected(payload) {
    return {
      type: actions.SELECTEDS_CHANGED,
      payload,
    };
  },

  doReset: (filter) => async (dispatch) => {
    dispatch(actions.doFetchApplicantAmount('application_received', {}));
    dispatch(actions.doFetch({ dealStage: filter.dealStage }));
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: actions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await ApplicantService.list(filter, selectors.selectOrderBy(getState()), null, null);

      new Exporter(exporterFields, i18n('entities.applicant.exporterFileName')).transformAndExportAsExcelFile(
        response.rows,
      );

      dispatch({
        type: actions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.EXPORT_ERROR,
      });
    }
  },

  doChangePaginationAndSort: (pagination, sorter) => async (dispatch, getState) => {
    dispatch({
      type: actions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch({
      type: actions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(actions.doFetchCurrentFilter());
  },

  doChangeLastDealstage: (dealstage) => async (dispatch, getState) => {
    dispatch({
      type: actions.DEALSTAGE_CHANGED,
      payload: dealstage,
    });
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    dispatch(actions.doFetch(filter, true));
  },

  doFetchFollowUpToday: () => async (dispatch, getState) => {
    const dateNow = moment(new Date()).format('YYYY-MM-DD');
    const filter = {
      followUpRange: [dateNow, dateNow],
    };
    dispatch(actions.doFetch(filter, true));
  },

  doFetch:
    (filter?, keepPagination = false) =>
    async (dispatch, getState) => {
      const allFilters = JSON.parse(JSON.stringify(filter));
      const applicantOwner = localStorage.getItem(APPLICANT_OWNER);
      const currentUser = authSelectors.selectCurrentUser(getState());
      if (currentUser?.id === applicantOwner) {
        allFilters.owner = currentUser.id;
      }
      try {
        dispatch({
          type: actions.FETCH_STARTED,
          payload: { filter: allFilters, keepPagination },
        });

        const response = await ApplicantService.list(
          allFilters,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: actions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.FETCH_ERROR,
        });
      }
    },
  doFetchApplicantAmount: (dealStage, filter) => async (dispatch, getState) => {
    try {
      dispatch({
        type: actions.NUMBER_FETCH_STARTED,
      });
      const allFilters = JSON.parse(JSON.stringify(filter));
      const applicantOwner = localStorage.getItem(APPLICANT_OWNER);
      const currentUser = authSelectors.selectCurrentUser(getState());
      if (currentUser?.id === applicantOwner) {
        allFilters.owner = currentUser.id;
      }
      const response = await ApplicantService.getNumberOfApplicantsPerDealstage({
        dealStage: dealStage,
        filter: allFilters,
      });

      dispatch({
        type: actions.NUMBER_FETCH_SUCCESS,
        payload: {
          dealStageCount: response,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FETCH_ERROR,
      });
    }
  },
};

export default actions;

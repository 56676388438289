import React, { FC } from 'react';
import './card.scss';
import { QuestionOutlined } from '@ant-design/icons';
import { Card } from '../../../../base/card/card';
import { Divider, List } from 'antd';
import CollapsableCard from 'src/components/base/collapsable/collapsable';

interface QuestionnaireCardProps {
  record: any;
}

export const QuestionnaireCard: FC<QuestionnaireCardProps> = ({ record }) => {
  return (
    <CollapsableCard title={'Fragebogen'} icon={<QuestionOutlined />} panelKey="applicant-question-card">
      <Divider orientation="left" style={{ marginTop: '8px', fontSize: '14px' }}>
        Fragebogen Antworten (Funnel)
      </Divider>
      <List
        size="small"
        dataSource={record.questionnaire}
        renderItem={(item: any) => (
          <List.Item key={item.id}>
            <List.Item.Meta title={item.question} description={item.answer} />
          </List.Item>
        )}
      />
      {record.telephoneQuestionnaire?.length > 0 && (
        <>
          <Divider orientation="left" style={{ marginTop: '8px', fontSize: '14px' }}>
            Telefonische Fragen (Leadservice)
          </Divider>
          <List
            size="small"
            dataSource={record.telephoneQuestionnaire}
            renderItem={(item: any) => (
              <List.Item key={item.id}>
                <List.Item.Meta title={item.question} description={item.answer} />
              </List.Item>
            )}
          />
        </>
      )}
    </CollapsableCard>
  );
};

import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class LeadService {
  static async update(id, data) {
    data.sourceUrl = window.location.href;
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(`/tenant/${tenantId}/lead/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(`/tenant/${tenantId}/lead`, {
      params,
    });

    return response.data;
  }

  static async archiveAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/lead/archive`, params);

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/lead`, body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/lead/import`, body);

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/lead/${id}`);

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/lead`, {
      params,
    });

    return response.data;
  }

  static async getNumberOfLeadsPerDealstage({ dealStage, filter }) {
    const allFilters = { ...filter };
    delete allFilters.dealStage;
    const tenantId = AuthCurrentTenant.get();
    const params = {
      filter: allFilters,
    };
    const response = await authAxios.get(`/tenant/${tenantId}/leads/` + dealStage, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/lead/autocomplete`, {
      params,
    });

    return response.data;
  }
}

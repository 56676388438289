import actions from 'src/modules/lead/list/leadListActions';

const INITIAL_PAGE_SIZE = 50;

const initialData = {
  rows: [],
  count: 0,
  loading: false,
  filter: { dealStage: 'new' },
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  sorter: {},
  selectedKeys: [],
  dealStageCount: {
    new: 0,
    contact_attempt: 0,
    appointment_agreed: 0,
    appointment_failed: 0,
    appointment_is_done: 0,
    offer_sent: 0,
    assignment: 0,
    not_suitable: 0,
    archived: 0,
    all: 0,
  },
  budget: {
    assignment_budget: 0,
    offer_sent_budget: 0,
  },
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.SELECTEDS_CHANGED) {
    return {
      ...state,
      selectedKeys: payload || [],
    };
  }

  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_CHANGED) {
    return {
      ...state,
      sorter: payload || {},
    };
  }

  if (type === actions.DEALSTAGE_CHANGED) {
    return {
      ...state,
      dealstageIndex: payload || {},
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      selectedKeys: [],
      filter: payload ? payload.filter : {},
      pagination:
        payload && payload.keepPagination
          ? state.pagination
          : {
              current: 1,
              pageSize: INITIAL_PAGE_SIZE,
            },
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }

  if (type === actions.NUMBER_FETCH_SUCCESS) {
    return {
      ...state,
      dealStageCount: payload.dealStageCount,
      budget: payload.budget,
    };
  }

  if (type === actions.EXPORT_STARTED) {
    return {
      ...state,
      exportLoading: true,
    };
  }

  if (type === actions.EXPORT_SUCCESS) {
    return {
      ...state,
      exportLoading: false,
    };
  }

  if (type === actions.EXPORT_ERROR) {
    return {
      ...state,
      exportLoading: false,
    };
  }

  return state;
};

import actions from 'src/modules/applicant/list/applicantListActions';

const INITIAL_PAGE_SIZE = 50;

const initialData = {
  rows: [],
  count: 0,
  loading: false,
  filter: { dealStage: '' },
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  sorter: {},
  selectedKeys: [],
  dealStageCount: {
    application_received: 0,
    contact_attempt: 0,
    phone_interview: 0,
    interview: 0,
    screening: 0,
    offer: 0,
    hired: 0,
    not_suitable: 0,
    archived: 0,
    all: 0,
    deleted: 0,
  },
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.SELECTEDS_CHANGED) {
    return {
      ...state,
      selectedKeys: payload || [],
    };
  }

  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_CHANGED) {
    return {
      ...state,
      sorter: payload || {},
    };
  }

  if (type === actions.DEALSTAGE_CHANGED) {
    return {
      ...state,
      dealstageIndex: payload || {},
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      selectedKeys: [],
      filter: payload ? payload.filter : {},
      pagination:
        payload && payload.keepPagination
          ? state.pagination
          : {
              current: 1,
              pageSize: INITIAL_PAGE_SIZE,
            },
    };
  }
  if (type === actions.RESET_FILTER) {
    return {
      ...state,
      loading: true,
      selectedKeys: [],
      filter: initialData.filter,
      pagination:
        payload && payload.keepPagination
          ? state.pagination
          : {
              current: 1,
              pageSize: INITIAL_PAGE_SIZE,
            },
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }

  if (type === actions.NUMBER_FETCH_SUCCESS) {
    return {
      ...state,
      dealStageCount: payload.dealStageCount,
    };
  }

  if (type === actions.EXPORT_STARTED) {
    return {
      ...state,
      exportLoading: true,
    };
  }

  if (type === actions.EXPORT_SUCCESS) {
    return {
      ...state,
      exportLoading: false,
    };
  }

  if (type === actions.EXPORT_ERROR) {
    return {
      ...state,
      exportLoading: false,
    };
  }

  return state;
};
